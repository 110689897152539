import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

import clock from '../../images/joanna/clock.svg';
import dumbbell from '../../images/joanna/dumbbell.svg';
import exercise from '../../images/joanna/exercise.svg';
import medal from '../../images/joanna/medal.svg';
import muscle from '../../images/joanna/muscle.svg';
import offline from '../../images/joanna/offline.svg';
import smartphone from '../../images/joanna/smartphone.svg';
import volume from '../../images/joanna/volume.svg';

const Joanna4 = (props) => (
    <section className="joanna4 pt-0">
        <Container fluid className="px-sm-0">
            <Row noGutters>
                <Col md="6">
                    <GatsbyImage image={props.img1} />
                </Col>
                <Col xs="6" md="3">
                    <GatsbyImage image={props.img2} />
                </Col>
                <Col xs="6" md="3">
                    <GatsbyImage image={props.img3} />
                </Col>
            </Row>
        </Container>
    
        <Container>
            <Row>
                <Col md="6" className="text-center margin-top">
                    <Row>
                        <Col xs="6">
                            <div className="round">
                                <img src={muscle} alt="" />
                            </div>
                            <h3>Różne poziomy<br/>trudności</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={medal} alt="" />
                            </div>
                            <h3>Certyfikowana<br/>trenerka</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={smartphone} alt="" />
                            </div>
                            <h3>Wysoka jakość<br/>nagrań</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={volume} alt="" />
                            </div>
                            <h3>Instrukcje głosowe</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={exercise} alt="" />
                            </div>
                            <h3>Ponad 400<br/>ćwiczeń</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={dumbbell} alt="" />
                            </div>
                            <h3>8 programów<br/>treningowych</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={clock} alt="" />
                            </div>
                            <h3>Elastyczny wybór<br/>czasu trwania</h3>
                        </Col>
                        <Col xs="6">
                            <div className="round">
                                <img src={offline} alt="" />
                            </div>
                            <h3>Dostęp<br/>offline</h3>
                        </Col>
                    </Row>
                </Col>

                <Col md="6">
                    <section>
                        <h2>Ze sprzętem lub bez. Ty decydujesz!</h2>
                        <p>Do wyboru masz plany treningowe wykorzystujące piłkę lekarską, piłkę gimnastyczną, kettle, skakankę, mini band oraz worek bokserski. Jeśli ich nie posiadasz, możesz wybrać wersje treningów bez sprzętu. Po prostu!</p>
                    </section>
                    <section className="pt-0">
                        <h2>Ciosy, kopnięcia i Piramida Challenge!</h2>
                        <p>Szukasz urozmaicenia codziennych treningów? Od teraz możesz wybrać 2, 3, lub 4-tygodniowe plany treningowe z Joanną Jędrzejczyk, dzięki którym zredukujesz masę ciała, poprawisz kondycję, a także skutecznie rozładujesz napięcie. Zaczynamy?</p>
                    </section>
                </Col>

            </Row>
        </Container>
    </section>
);

export default Joanna4;
