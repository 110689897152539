import React  from 'react';
import { Container, Row, Col } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

const Joanna2 = (props) => (
    <section className="joanna2">
        <Container>   
            <Row className="align-items-center">
                <Col md="6"> 
                    <GatsbyImage image={props.phone1} />
                </Col>
                <Col md="6">
                    <h2>Trenuj pod okiem mistrzyni</h2>
                    <p>Do zespołu trenerów w Diet & Training dołączyła Joanna Jędrzejczyk! Pierwsza Polka w prestiżowej organizacji UFC (Ultimate Fighting Championship), reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA).</p>
                    <p>Wybierz jeden z jej 8 autorskich planów treningowych, stworzonych na różnych poziomach trudności. Zwiększ swoją wytrzymałość, popraw równowagę i zwinność.</p>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={{size: 12, order: 2}} md={{size: 5, order: 1}}>
                    <h2>8 planów treningowych na różnych poziomach trudności</h2>
                    <p>Droga do mistrzostwa jest sumą małych kroków. Dlatego pod okiem Joanny Jędrzejczyk możesz trenować wybierając spośród 8 planów treningowych.</p>
                    <p>Nieważne, czy dopiero zaczynasz, pierwsze kroki na macie masz już za sobą lub czujesz, że jesteś gotowa na prawdziwie mistrzowski, intensywny trening - na pewno znajdziesz tu coś właśnie dla siebie!</p>
                </Col>
                <Col xs={{size: 12, order: 1}} md={{size: 7, order: 2}}> 
                    <GatsbyImage image={props.phone2} />
                </Col>
            </Row>
        </Container>
    </section>
);

export default Joanna2;
