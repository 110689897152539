import React from "react";
import '../helpers/suppress-install-prompt';
import Seo from "../components/seo";
import Layout from "../components/Layout";

import Joanna1 from "../components/Joanna/Joanna1";
import Joanna2 from "../components/Joanna/Joanna2";
import Joanna3 from "../components/Joanna/Joanna3";
import Joanna4 from "../components/Joanna/Joanna4";
import Joanna5 from "../components/Joanna/Joanna5";
import Joanna6 from "../components/Joanna/Joanna6";
import Joanna7 from "../components/Joanna/Joanna7";
import Joanna8 from "../components/Joanna/Joanna8";

import SaleProductsList from "../components/SaleProduct/SaleProductsList";
import SaleProductsGroup from "../SaleProduct/SaleProductsGroup";

import { graphql} from "gatsby";

const Page = ({ data }) => {

    const productsGroups = [
        new SaleProductsGroup(
            'landing:main:workout',
            'Trening',
            'Dostęp do wszystkich planów treningowych',
            'black'
        ),
    ];

    return (
        <Layout page="joanna" showMenu={true}>
            <Seo />
            
            <Joanna1 hero={data.hero.childImageSharp.gatsbyImageData} />
            <Joanna2
                phone1={data.phone1.childImageSharp.gatsbyImageData}
                phone2={data.phone2.childImageSharp.gatsbyImageData}
            />
            <Joanna3 />
            <Joanna4
                img1={data.img1.childImageSharp.gatsbyImageData}
                img2={data.img2.childImageSharp.gatsbyImageData}
                img3={data.img3.childImageSharp.gatsbyImageData}
            />
            <Joanna5
                jjandann={data.jjandann.childImageSharp.gatsbyImageData}
                jjandann2={data.jjandann2.childImageSharp.gatsbyImageData}
                training1={data.training1.childImageSharp.gatsbyImageData}
                training2={data.training2.childImageSharp.gatsbyImageData}
                training3={data.training3.childImageSharp.gatsbyImageData}
                training4={data.training4.childImageSharp.gatsbyImageData}
            />
            <Joanna6 />
            <Joanna7 />
            <Joanna8 />
            <SaleProductsList productsGroups={productsGroups} source="joanna" promoCode="jjpremiera" />

        </Layout>
    );
}

export default Page;

export const query = graphql`
    query {
        hero: file(relativePath: { eq: "joanna/hero.png" }) {...fluidImage1920}
        phone1: file(relativePath: { eq: "joanna/phone1.png" }) {...fluidImage1920}
        phone2: file(relativePath: { eq: "joanna/phone2.png" }) {...fluidImage1920}
        img1: file(relativePath: { eq: "joanna/img1.png" }) {...fluidImage1920}
        img2: file(relativePath: { eq: "joanna/img3.jpg" }) {...fluidImage1920}
        img3: file(relativePath: { eq: "joanna/img3.png" }) {...fluidImage1920}

        jjandann: file(relativePath: { eq: "joanna/jjandann.png" }) {...fluidImage1920}
        jjandann2: file(relativePath: { eq: "joanna/jjandann2.png" }) {...fluidImage1920}

        training1: file(relativePath: { eq: "joanna/training1.png" }) {...fluidImage480}
        training2: file(relativePath: { eq: "joanna/training2.png" }) {...fluidImage480}
        training3: file(relativePath: { eq: "joanna/training3.png" }) {...fluidImage480}
        training4: file(relativePath: { eq: "joanna/training4.png" }) {...fluidImage480}

    }
`;
