import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { GatsbyImage } from "gatsby-plugin-image";

import { scroller } from 'react-scroll';

const Joanna5 = (props) => (
    <section className="joanna5">

        <div className="bgcolor">
            
            <div className="asana2">
                <GatsbyImage image={props.jjandann} className="d-none d-md-block" />
                <GatsbyImage image={props.jjandann2} className="d-md-none" />
            </div>
            <Container className="text-center">
                <h2>Na start aż osiem programów o trudności</h2>
                <h3><span className="tag">easy</span> <span className="tag">medium</span> <span className="d-none d-md-inline">oraz</span> <span className="tag">hard</span></h3>

                <Row>
                    <Col md="3">
                        <div className="box">
                            <GatsbyImage image={props.training1} />
                        </div>
                        <p className="lead">Zacznijmy wspólne treningi: Introduction i Body Combat</p>
                        <p>Pierwsza seria programów dedykowana jest dla każdego. Z nimi nauczysz się podstawowych ciosów i pozycji bokserskich.</p>
                        <p>Nie zabraknie energetycznego fitnessu i intensywnych treningów.</p>
                    </Col>
                    <Col md="3">
                        <div className="box">
                            <GatsbyImage image={props.training2} />
                        </div>
                        <p className="lead">PunchHIIT i PunchHIIT+! Skakanka gotowa?</p>
                        <p>Z serią PunchHIIT zaczniesz budować bazę tlenową, wytrzymałość siłową, a to wszystko z elementami sztuk walki, które pojawią się w 3-tygodniowym planie treningowym.</p>
                        <p>PS: przed treningiem przygotuj skakankę.</p>
                    </Col>
                    <Col md="3">
                        <div className="box">
                            <GatsbyImage image={props.training3} />
                        </div>
                        <p className="lead">Seria Dynamic Body Combat</p>
                        <p>W tym miesięcznym planie treningowym poza wzmacnianiem i rzeźbą całego ciała skupimy się na Twojej zwinności i równowadze!</p>
                        <p>Gwarantuję, że będzie intensywnie, ale efekty zrekompensują trud… i to z nawiązką!</p>
                    </Col>   
                    <Col md="3">
                        <div className="box">
                            <GatsbyImage image={props.training4} />
                        </div>
                        <p className="lead">Zapraszam Cię na moje Ultimate Classes</p>
                        <p>Tak trenuję na co dzień. Chcesz do mnie dołączyć? To przygotuj worek bokserski i weź głęboki oddech. Będzie intensywnie!</p>
                        <p>Przed Tobą 45-minutowe sesje treningowe, w których damy z siebie wszystko! Zaczynajmy!</p>
                    </Col>                    
                </Row>    

                <Button
                    color="primary"
                    onClick={() =>
                        scroller.scrollTo('buy', {
                            duration: 1000,
                            smooth: true,
                            offset: -60,
                        })
                    }
                >
                    Uzyskaj dostęp
                </Button>  
            </Container>
        </div>
        
    </section>
);

export default Joanna5;
