import React  from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { scroller } from 'react-scroll';
import jj from '../../images/joanna/jj.png';
import jj2 from '../../images/joanna/jj2.png';
import { USER_COUNT } from '../../constants';

const Joanna7 = () => (
    <section className="joanna7">

        <Container className="text-center">
        <h2>Dołącz do ponad <br/>{USER_COUNT} użytkowników!</h2>            
        </Container>

        <div className="social-proof">
            <Container>
                <Row>
                    <Col md={{size: 10, offset: 1}}>
                        <div className="box">
                            <span className="tag">TWOJA TRENERKA</span>
                            <h3>Joanna Jędrzejczyk</h3>
                            <p>Reprezentantka Polski w boksie, kick-boxingu, muay thai oraz mieszanych sztukach walki (MMA). Trzykrotna zawodowa mistrzyni świata, dwukrotna mistrzyni Europy oraz czterokrotna amatorska mistrzyni świata, trzykrotna mistrzyni Europy i pięciokrotna mistrzyni Polski w boksie tajskim.</p>
                            <p>Pierwsza Polka w prestiżowej organizacji Ultimate&nbsp;Fighting Championship.</p>
                            <img srcSet={`${jj} 100w`} sizes="50px" src={jj} className="d-none d-md-block" alt="" />
                            <img src={jj2} alt="" className="d-md-none img-fluid" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container className="text-center"> 
            <Button
                color="primary"
                className="mt-md-5"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >Uzyskaj dostęp</Button>
        </Container>
    
    </section>
);

export default Joanna7;
