import React  from 'react';
import { Container, Button } from 'reactstrap';
import { scroller } from 'react-scroll';

import iconBoxing from '../../images/joanna/boxing_icon.svg';

const Joanna3 = () => (
    <section className="joanna3">
        <Container className="text-center">
            <img src={iconBoxing} alt="" />
            <h2>
                Dołącz do treningów elementy sztuk walki.<br/>
                Wzmocnij ciało i wyrzeźb mięśnie.
            </h2>

            <Button
                color="white"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >
                Uzyskaj dostęp
            </Button>

        </Container>    
    </section>
);

export default Joanna3;
