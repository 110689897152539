import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import appStore from '../../images/yoga/badge_apple.svg';
import googlePlay from '../../images/yoga/badge_google.svg';
import appIcon from '../../images/yoga/hpba_app_icon.jpg';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../constants';

const Joanna6 = () => (
    <section className="joanna6">

        <Container className="text-center">
            <Row className="align-items-center">
                <Col md='5' className="text-md-right">
                    DOSTĘPNE TYLKO<br/>
                    W APLIKACJI<br/>
                    <strong>DIET</strong>&nbsp;&&nbsp;<strong>TRAINING</strong><br/>
                    BY&nbsp;ANN
                </Col>
                <Col md="2">
                    <img src={appIcon} className="app-icon img-fluid my-3 my-md-0" alt="" />
                </Col>
                <Col md="3">
                    <a
                        href={GOOGLE_PLAY_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={googlePlay} className="img-fluid" alt="" />
                    </a>
                    <a
                        href={APP_STORE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={appStore} className="img-fluid" alt="" />
                    </a>
                </Col>
            </Row>
            
        </Container>
    
    </section>
);

export default Joanna6;
