import React  from 'react';
import { Container, Button } from 'reactstrap';
import { scroller } from 'react-scroll';
import { GatsbyImage } from "gatsby-plugin-image";

const Joanna1 = (props) => (    
    <section className="joanna1">
        <Container>   
            <div className="tag">
                <strong>NOWOŚĆ</strong><span>treningi dostępne od 9.11</span>
            </div>

            <br/>
            <div className="title">
                <h1>Joanna Jędrzejczyk</h1>
                <h2>w Diet & Training by Ann</h2>
            </div>
            <br/>
            <Button
                color="primary"
                onClick={() =>
                    scroller.scrollTo('buy', {
                        duration: 1000,
                        smooth: true,
                        offset: -60,
                    })
                }
            >
                Uzyskaj dostęp
            </Button>
            
        </Container>
        <div className="img-container">
            <GatsbyImage image={props.hero} />
        </div>
    </section>
);

export default Joanna1;
