import React  from 'react';
import { Container, Row, Col } from 'reactstrap';

import ania from '../../images/joanna/ania.png';
import ewelina from '../../images/joanna/ewelina.png';
import kuba from '../../images/joanna/kuba.png';

const Joanna8 = () => (
    <section className="joanna8">

        <Container className="text-center">
            <h2>Wybierając dowolny pakiet treningowy otrzymasz dostęp do ćwiczeń z wszystkimi trenerami</h2>            
            <Row>
                <Col>
                    <div className="box">
                        <img srcSet={`${ewelina} 100w`} sizes="50px" src={ewelina} alt="" />
                        <p>
                            <strong>EWELINA</strong><br/>
                            GODLEWSKA
                        </p>
                    </div>

                    <p className="text-center"><strong>YOGA</strong><br/><span className="d-none d-md-block">&nbsp;</span></p>
                </Col>
                <Col className="col-12 col-md-5">
                    <div className="box">
                        <img srcSet={`${ania} 100w`} sizes="50px" src={ania} alt="" />
                       <p>
                           <strong>ANIA</strong><br/>
                            LEWANDOWSKA
                        </p>
                    </div>
                    <p className="text-center">
                        <strong>TRENINGI DLA KOBIET<br/>
                        TRENINGI W PARACH</strong>
                    </p>
                </Col>
                <Col>
                    <div className="box kuba">
                        <img srcSet={`${kuba} 100w`} sizes="50px" src={kuba} alt="" />
                        <p>
                            <strong>KUBA</strong><br/>
                            GŁĄB
                        </p>
                    </div>
                    <p className="text-center">
                        <strong>TRENINGI DLA MĘŻCZYZN<br/>
                        TRENINGI W PARACH</strong>
                    </p>
                </Col>
            </Row>
        </Container>
    </section>
);

export default Joanna8;
